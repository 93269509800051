.wrapper{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.accordion{
    width: 70%;

}

.item{
    background: black;
    margin-bottom: 5px;
    padding: 10px 20px ;
}

.title{
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.content{
    color:white;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
}

.content.show{
    height: auto;
    max-height: 9999px;
    transition :all 0.5s cubic-bezier(1,0,1,0) ;
}